@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Fredoka+One&family=Gloria+Hallelujah&display=swap');
@font-face { font-family: balgin; src: url('./fonts/Ananias.otf'); } 
@font-face { font-family: Ananias; src: url('./fonts/Balgin2.ttf'); }
@font-face { font-family: BalginBold; src: url('./fonts/BalginBold.ttf'); } 

html {
  scroll-behavior: smooth;
  
}
.App {
  text-align: center;
  font-family: "Ananias","Arial","Chewy","Fredoka One","Gloria Hallelujah";
  color: black;
  
}

#rdmp{
  display:none;
  transition: all 2s;
}


.sale{
  color:#649f5f;
  font-size: 10;
}

.minting{
  margin-top: 80px;
  
}

i{
  text-decoration: none;
  color: white;
}

p{
  width:50%;
}
.grid-item{
  align-self: center;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-shadow: 1px 1px 2px black;
  font-family: "Ananias","Arial","Chewy","Fredoka One","Gloria Hallelujah";
}


h1{
  text-align: center;
    color: white;
    font-size: 4vw;
    -webkit-text-stroke: 2.5px #003f37;
    font-weight: 500;
    line-height: 40px;
    -webkit-text-fill-color: white;
    font-family: "balgin","Arial","Chewy","Fredoka One","Gloria Hallelujah";
}

h4{
  font-family: "balgin","Arial","Chewy","Fredoka One","Gloria Hallelujah";
  font-size: 2.5vw;
  margin-bottom: 0;
}
h4{
  font-size: 2.5vw;
}

.material-icon{
  color:red;
}
.logoletra{
  width: 10vw;
  height: auto;
}

#shells{
  width: 6vw;
  height: auto;
}

#Roadmap{
  width: 50vw;
  height: auto;
  transition: height 2s;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
}

.link{
  text-decoration: none;
  color: white;
}
.link:hover{
  color:#003f37;
}


h2{
  text-align: center;
    color: #649f5f;
    font-size: 50px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 50px;
   
    text-shadow: 1px 1px 2px black;

    -webkit-text-stroke: 1.5px #003f37;
    -webkit-text-fill-color: #649f5f;
    font-family: "balgin","Arial","Chewy","Fredoka One","Gloria Hallelujah";
}

h3{
  text-align: center;
    color: white;
    font-size: 2em;
    font-weight: 500;
    line-height: 40px;
   
   
    text-shadow: #98c159 -.5px 1.75px 0px,#003f37 -1.75px 3px 0px;

    -webkit-text-stroke: 1.8px #003f37;
    -webkit-text-fill-color: white;
    font-family: "balgin","Arial","Chewy","Fredoka One","Gloria Hallelujah";
}



* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
}

.texti{
    width: 50%; /* Can be in percentage also. */
    margin: 0 auto;
    position: relative;
    
}
.connected{
  color:#649f5f;
  font-family:"BalginBold";
}

.overlay {
  opacity: 0.85;
  height: 120%;
  z-index: 10;
  top: 0;
  left: 0;
  position: static;
}

.last{
  margin-bottom:30vw;
}

.last2{
  margin-bottom: 20vw;
}

.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background/bg1.png");
  background-color: #71c8f1;
  background-repeat: no-repeat;;
  background-size: cover ;
  background-attachment: scroll;
  background-position: 40% 40%;
  
}

.home{
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 0px;
}

.show{
  display: none;
}

.menu{
  display: none;
}

.hide{
  text-decoration: none;
  color: #444;
}

.logo{
  text-decoration: none;
}



.separator{
  flex-grow:1;
  width: 100%;
  
}

#corazon{
  color:red;
  vertical-align: middle;
}

.menuburger{
  display:none;
}



.addy{
  color:#003f37;
}

.first{
  visibility:visible;
}

.footer-07{
  color: white;
  text-decoration: none;
  background-color:  #7d8184 ;
  overscroll-behavior-y: none;
}
.start{
  padding-top: 150px;
}
.end{
  margin-bottom: 150px;
}


.one{
  background: #6bc8f3;
  float:bottom;
  clear:none;
  display:block;
}


.two{
  background: #3f806c;
  display:block;

}
.three{
  background: #589552 ;
  display:block;

}
.four{
  background:  #98c159;
  display:block;


}
.five{
  background:  #e4ce93;
  display:block;


}
.six{
  background:  #ccf2ff;
  display:block;
}
.seven{
  background:  #7d8184;
  display:block;
}
faq{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
details{
  font-size: 1.3rem;
  background-color: #00000016;
  border-radius: 25px;
  padding: 10px;
  margin:10px;
  width: 100%;
}
details summary {
  list-style-type: "+ ";
  cursor: pointer;
  border-radius: 15px;
}



summary{
  list-style-type: "+ ";
  font-family: "Balgin","Arial","Chewy","Fredoka One","Gloria Hallelujah";
  font-size: 2rem;
  color:white;
  background-color: #00000030;
  width:30vw;
  padding: 10px;
  width: 100%;
}

details[open] summary ~ * {
  list-style-type: "↓ ";
  cursor: pointer;
  border-radius: 25px;
  width:auto;
  border-radius: 15px;
  margin-bottom: 15px;
  animation: sweep 1s ease-in-out;
}

@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 0px}
}

.notbg{
  display:flex;
  flex-direction: column;
  text-align: center;
  color:white;
  font-size: 22px;;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 6%;
  padding-bottom: 6%;
  text-shadow: .5px 1px 2px black;
  align-items: center;
}

.grid{
  float:bottom;
  clear:none;
  width:"100%"; 
  display: flex;
  flex-direction: column;
  border: none;
}

#WHATISDIS
.bght{
  font-family: "Ananias","Arial","Chewy","Fredoka One","Gloria Hallelujah";
  background-color:"#649f5f";
  border-radius:"25px";
  border-width:"0";
  color:"white";
  height:"15px";
  width:"120px";
  cursor:"pointer";
  padding:"15px";
  margin:"0 15px";
}

.rdmp{
  width:"250px";
}

.ennzu{
  display: flex;
  align-items:stretch;
  width: 20vw;
  height: 8vw;
  background-color: #6bc8f3;
  border-radius: 25px;
}

.team{
  display: flex;
  align-items: normal;
  width: 100%;
}

.titleteam{
  color:#457D98;
  font-weight: bolder;
  text-shadow: none;
  padding-top: 33%;
}

.texteam{
  text-shadow: none;
  font-size: 1.5vw;
  padding-top: 1.5%;
  vertical-align: center;
}

.separator1{
  width:100%;
  height: 65vh;
}

a:link{
  text-decoration: none;
}

a:visited{
  text-decoration: none;
}

a:active{
  text-decoration: none;
}

.copyright{
  text-shadow: .5px 1px 1px black;
  font-family: "Ananias","Arial","Chewy","Fredoka One","Gloria Hallelujah";
  font-size:24px;
  width:95%;
  text-decoration: none;
  margin-bottom: 5vh;
}
svg{
  margin:-1px;
}

.copyright:hover{
  text-decoration: none;
  font-family:"Ananias";
}

@media screen and (max-width: 1024px) and (min-width: 921px){
  .hr-line{
    width: 75%;
  }
  .faq-page {
    width: 75%
  }

  #Roadmap{
    width: 60vw;
  }
  h4{
    font-size: 2vw;
  }
  .hide {
      display: none !important;
  }
  .copyright{
    margin:auto;
    margin-top: 1vh;
    margin-bottom: 5vh;
    font-size:11px;
  }
  .menu{
    display: block !important;
  }

  h1{
      font-size: 4vw;
      -webkit-text-stroke: 2px #003f37;
    }
  .logoletra{
    width: 170px;
  }

  Button{
    width: 20vw;
    height: 5vw;
  }
  
  #shells{
    width: 25vw;
  }
  .css-17xejub{
    display:none;
  }

  .h1{
    font-size: 2em;
    -webkit-text-stroke: 2px #003f37;
  }

  .last{
    margin-bottom:50vw;
  }
  .copyright{
    font-size:20px;
    
  }
}

@media screen and (max-width: 420px) {
  summary{
    font-size: 1.7rem;
  }
  details{
    font-size: 1.1rem;
  }

  .hr-line{
    width: 90%;
  }
  .faq-page {
    width: 90%
  }
  h4{
    font-size: 1.5vw;
  }
  .hide {
    display: none !important;
}
#Roadmap{
  width: 70vw;
}
.menu{
  display: block !important;
}
.copyright{
  margin:auto;
  margin-top: 1vh;
  margin-bottom: 5vh;
  font-size:11px;
  width: 80%;
}
  h1{
    font-size: 9vw;
    -webkit-text-stroke: 0px #003f37;
    color:#003f37;
  }
  p{
    width:80%;
  }
  .logoletra{
    width: 170px;
  }
  Button{
    width: 20vw;
    height: 5vw;
  }
  
  #shells{
    width: 25vw;
  }
  .css-17xejub{
    display:none;
  }
}

@media screen and (max-width: 500px) and (min-width: 421px) {
  summary{
    font-size: 1.7rem;
  }
  details{
    font-size: 1.1rem;
  }
  .hr-line{
    width: 90%;
  }
  .faq-page {
    width: 90%
  }
  h4{
    font-size: 1.5vw;
  }
  .hide {
    display: none !important;
  }
  #Roadmap{
    width: 80vw;
  }
  .menu{
    display: block !important;
  }
  .copyright{
    margin:auto;
    margin-top: 1vh;
    margin-bottom: 5vh;
    font-size:13px;
    width: 80%;
  }
  h1{
    font-size: 7vw;
    -webkit-text-stroke: 0px #003f37;
    color:#003f37;
  }
  p{
    width:80%;
  }
  .logoletra{
    width: 170px;
  }
  Button{
    width: 20vw;
    height: 5vw;
  }
  
  #shells{
    width: 25vw;
  }
  .css-17xejub{
    display:none;
  }
}

@media screen and (max-width: 720px)and (min-width: 501px){
  summary{
    font-size: 1.7rem;
  }
  details{
    font-size: 1.1rem;
  }
  .hr-line{
    width: 90%;
  }
  .faq-page {
    width: 90%
  }
  h4{
    font-size: 1.5vw;
  }
  #Roadmap{
    width: 80vw;
  }
  Button{
    width: 20vw;
    height: 5vw;
  }
  
  #shells{
    width: 25vw;
  }
  .css-17xejub{
    display:none;
  }
  .hide {
    display: none !important;
  }
  .menu{
  display: block !important;
  }
  h1{
    font-size: 6vw;
    -webkit-text-stroke: 0px #003f37;
    color:#003f37;
  }
  p{
    width:80%;
  }
  .logoletra{
    width: 170px;
  }

  .copyright{
    margin:auto;
    margin-top: 1vh;
    margin-bottom: 5vh;
    font-size:16px;
  }
}

@media screen and (max-width: 920px)and (min-width: 721px) {
  summary{
    font-size: 1.7rem;
  }
  details{
    font-size: 1.1rem;
  }
  .hr-line{
    width: 90%;
  }
  .faq-page {
    width: 90%
  }
  h4{
    font-size: 1.5vw;
  }
  #Roadmap{
    width: 80vw;
  }
  Button{
    width: 20vw;
    height: 5vw;
  }
  
  #shells{
    width: 25vw;
  }
  .css-17xejub{
    display:none;
  }
  .logoletra{
    width: 170px;
  }
  .hide {
    display: none !important;
  }
  .menu{
    display: block !important;
  }
  .last{
    margin-bottom:70vw;
  }


  .separator1{
    height:62vh;
  }
  p{
    width:80%;
  }
  .copyright{
    margin:auto;
    margin-top: 1vh;
    margin-bottom: 5vh;
    font-size:18px;
  }
  h1{
    font-size: 5vw;
    -webkit-text-stroke: 2px #003f37;
  }
}
